import React, { Component } from "react";
import { getString } from "./index";

import Footer from "./footer";
import data from "./data/data.json";

export default class ProjectDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            project: props.match.params.project,
            push: props.history.push,
            loaded: 0
        }

        window.scrollTo(0, 0);
    }

    componentDidMount() {
        if(data.projects[this.state.project]) {
            this.setState({ loaded: 1 });
        } else {
            this.state.push("/");
        }
    }

    goBack = () => {
        document.getElementById("nest").classList.add("routing");

        setTimeout(() => this.state.push("/"), 400);
    }

    render() {
        if(this.state.loaded === 1) {
            const project = this.state.project;
            const projectData = data.projects[this.state.project];

            return(
                <div id="ProjectDetails">
                    <div id="nest" className="mount">
                        <div className="cover">
                            <img src={ "images/" + project + "/cover.jpg" } alt={ projectData.title } />

                            <svg className="coverTint">
                                <defs>
                                    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                        <stop offset="0" stopColor="#00010d" stopOpacity="0.3"/>
                                        <stop offset="1" stopColor="#00010d"/>
                                    </linearGradient>
                                </defs>
                                <rect fill="url(#linear-gradient)"/>
                            </svg>

                            <div className="coverNav">
                                <div className="coverNavBack" onClick={ () => this.goBack() }>
                                    <div className="coverNavBackGo"><i className="icon ion-ios-arrow-round-back"></i></div>
                                    <div className="coverNavBackInit">{ getString("projectDetails.goBack") }</div>
                                </div>
                            </div>

                            <div className="coverInfo">
                                <div className="title">{ projectData.title }</div>
                                <div className="url"><a href={ projectData.url }>{ projectData.urlText }</a></div>
                            </div>
                        </div>

                        <div className="content">
                            <div className="desc">
                                <div className="descTitle">{ getString("projectDetails.jobs") }</div>
                                <div className="descText">{ projectData.jobs }</div>
                            </div>

                            <Images project={ project } projectData={ projectData } />
                        </div>

                        <Footer />
                    </div>

                    <div id="zoom" onClick={ () => document.getElementById("zoom").classList.remove("active") }>
                        <img id="zoomImg" src="" alt=""></img>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }
}

class Images extends Component {
    zoom = (url) => {
        const modal = document.getElementById("zoom");
        const img = document.getElementById("zoomImg");

        modal.classList.add("active");
        img.src = url;
    }

    render() {
        const { project, projectData } = this.props;

        return (
            <div className="images">
                <div className="imagesTitle">{ getString("projectDetails.imagesTitle") }</div>

                <div className="imagesFlow" onClick={ () => this.zoom("images/" + project + "/cover.jpg") } key="image0">
                    <img src={ "images/" + project + "/cover.jpg" } alt={ projectData.title }></img>
                </div>
            </div>
        )
    }
}