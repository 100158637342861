import React, { Component } from "react";
import { getString } from "./index";
import Rellax from "rellax";

import Footer from "./footer";
import data from "./data/data.json";

export default class Home extends Component {
    componentDidMount() {
        new Rellax("#background", { speed: -7, center: false, round: true });
    }

    render() {
        return(
            <div id="Home">
                <div id="background">
                    <div id="backgroundSolid"></div>
                    <svg id="backgroundWave" viewBox="0 0 1920 431.177">
                        <path d="M953.835,561.6h-.025a222.752,222.752,0,0,1-34.088-2.424,137.6,137.6,0,0,1-26.906-6.845,94.879,94.879,0,0,1-20.66-10.633A80.223,80.223,0,0,1,856.813,527.9c-7.93-9.25-13.971-20.545-18.468-34.531-4.278-13.3-6.644-27.473-8.394-39.583-.615-4.248-1.194-8.556-1.753-12.722l0-.012-.008-.056-.017-.131v-.014c-1.685-12.547-3.276-24.4-5.835-35.6A130.13,130.13,0,0,0,817.67,389.2a78.821,78.821,0,0,0-6.8-14.244,57.93,57.93,0,0,0-9.656-11.936,55.084,55.084,0,0,0-13.242-9.134,71.409,71.409,0,0,0-17.553-5.842,114.922,114.922,0,0,0-22.59-2.056c-46.086,0-117.409,21.422-218.042,65.49-27.337,11.971-54.253,22.5-80,31.3-24.712,8.446-49.015,15.525-72.234,21.039a603.85,603.85,0,0,1-64.845,11.841,472.6,472.6,0,0,1-57.838,3.711,381.877,381.877,0,0,1-50.7-3.286,322.7,322.7,0,0,1-44.582-9.169,287.148,287.148,0,0,1-38.83-14.02,269.5,269.5,0,0,1-33.446-17.839A265.306,265.306,0,0,1,58.88,414.426,270.57,270.57,0,0,1,35.1,392.047,286.531,286.531,0,0,1,0,346.163V130.421H1920v93.888c-5.3,1.688-11.014,3.307-17,4.813-49.11,12.365-121.484,18.875-215.114,19.35-161.32.819-276.3,83.136-387.5,162.743l-.016.011-.031.022c-25.363,18.157-51.59,36.933-77.7,54.193-14.06,9.293-26.991,17.438-39.535,24.9-14.081,8.377-27.336,15.7-40.523,22.384-14.152,7.175-28.256,13.6-41.92,19.086a440.818,440.818,0,0,1-43.729,15.006,394.2,394.2,0,0,1-45.944,10.149,382.055,382.055,0,0,1-48.572,4.505C959.5,561.555,956.616,561.6,953.835,561.6Z" transform="translate(0 -130.421)"/>
                    </svg>
                </div>

                <div id="nest" className="mount">
                    <div className="head">
                        <div className="headLeft">
                            <div className="headLeftTitle">{ getString("head.title") + " " }</div>
                            <div className="headLeftText">{ getString("head.text") }</div>

                            <div className="headLeftSocial">
                                <a href="https://dev.to/ozanbolel" className="icon"><img src="images/dev.svg" alt="" /></a>

                                <a href="https://twitter.com/oznbll" className="icon"><i className="ion-logo-twitter" /></a>

                                <a href="https://github.com/ozanbolel" className="icon"><i className="ion-logo-github" /></a>
                            </div>
                        </div>

                        <div className="headRight">
                            <svg className="headRightCode" viewBox="0 0 670 508.839">
                                <g transform="translate(-975 -107.877)">
                                    <path d="M701.582,239.693C672.134,123.9,570.382,40.308,450.946,36.955c-63.141-1.772-129.584,19.169-177.96,92.45-86.476,131,5.314,221.522,69.308,264.537a400.424,400.424,0,0,1,82.766,74.39c43.225,51.294,126.382,115.034,225.417,15.164C722.256,411.109,721.2,316.825,701.582,239.693Z" transform="translate(849.192 71.024)" fill="#fff"/>
                                    <path d="M688.7,644.806c0,39.422,24.731,71.321,55.294,71.321" transform="translate(675.924 -162.547)" fill="#3f3d56"/>
                                    <path d="M778.487,715.627c0-39.865,27.6-72.122,61.7-72.122" transform="translate(641.427 -162.047)" fill="#000436"/>
                                    <path d="M721.229,650.609c0,37.446,15.771,67.747,35.26,67.747" transform="translate(663.425 -164.776)" fill="#000436"/>
                                    <path d="M778.487,703.128c0-50.938,31.9-92.156,71.321-92.156" transform="translate(641.427 -149.548)" fill="#3f3d56"/>
                                    <path d="M759.6,758.8s7.842-.242,10.2-1.924,12.062-3.692,12.649-.993,11.785,13.423,2.931,13.495-20.571-1.379-22.929-2.816S759.6,758.8,759.6,758.8Z" transform="translate(648.684 -204.713)" fill="#a8a8a8"/>
                                    <path d="M719.6,393.4l48.791-48.642L719.6,296.12l14.875-14.826,63.666,63.467-63.666,63.467L719.6,393.4Z" transform="translate(683.319 -0.593)" opacity="0.1"/>
                                    <path d="M494.6,432.98h19.834V413.147H494.6Zm99.564-19.834H574.331V432.98h19.833Zm-59.7,19.834H554.3V413.147H534.465Z" transform="translate(768.988 -85.342)" opacity="0.1"/>
                                    <path d="M304.541,392.4,255.7,343.761l48.79-48.642-14.875-14.826L226,343.761l63.666,63.467Z" transform="translate(932.173 -0.089)" opacity="0.1"/>
                                    <path d="M719.6,374.4l48.791-48.642L719.6,277.12l14.875-14.826,63.666,63.467-63.666,63.467L719.6,374.4Z" transform="translate(683.319 8.986)" fill="#000436"/>
                                    <path d="M494.6,413.981h19.834V394.147H494.6Zm99.564-19.834H574.331v19.834h19.833Zm-59.7,19.834H554.3V394.147H534.465Z" transform="translate(768.988 -75.763)" fill="#000436"/>
                                    <path d="M304.541,373.4,255.7,324.761l48.79-48.642-14.875-14.826L226,324.761l63.666,63.467Z" transform="translate(932.173 9.49)" fill="#000436"/>
                                    <path d="M726,787.256c0,10.2-149.985,45.57-335,45.57S56,797.459,56,787.256s149.985,8.621,335,8.621S726,777.052,726,787.256Z" transform="translate(919 -216.11)" fill="#3f3d56"/>
                                    <path d="M726,787.256c0,10.2-149.985,45.57-335,45.57S56,797.459,56,787.256s149.985,8.621,335,8.621S726,777.052,726,787.256Z" transform="translate(919 -216.11)" opacity="0.1"/>
                                    <ellipse cx="335" cy="18.474" rx="335" ry="18.474" transform="translate(975 552.672)" fill="#fff"/>
                                </g>
                            </svg>
                        </div>
                    </div>

                    <Projects push={ this.props.history.push } />

                    <Footer />
                </div>
            </div>
        )
    }
}

class Projects extends Component {
    itemOnClick = (i) => {
        document.getElementById("nest").classList.remove("mount");
        document.getElementById("nest").classList.add("routing");

        setTimeout(() => this.props.push("/" + i), 400);
    }
    
    renderItem = (i) => (
        <div className="projectsItem" key={ i } onClick={ () => this.itemOnClick(i) }>
            <img src={ "images/" + i + "/cover.jpg" } alt={ data.projects[i].title } />
            
            <div className="projectsItemTitle">
                <div className="projectsItemTitleGo"><i className="icon ion-ios-arrow-round-forward"></i></div>
                <div className="projectsItemTitleInit">{ data.projects[i].title }</div>
            </div>
        </div>
    )

    render() {
        const projects = Object.keys(data.projects);

        return(
            <div className="projects">
                <div className="projectsTitle">{ getString("projects.title") }</div>

                <div className="projectsFlow">
                    { projects.map((i) => this.renderItem(i)) }
                </div>
            </div>
        )
    }
}