import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./styles/main.css";
import localization from "./data/localization.json";

import Home from "./home";
import ProjectDetails from "./project";

const userLanguage = navigator.language || navigator.userLanguage;
let uiLanguage = "tr";

if(userLanguage.includes("en")) { uiLanguage = "en"; }

export const getString = (str) => {
    str = str.split(".");

    return localization[str[0]][str[1]][uiLanguage];
}

class Root extends Component {
    render() {
        return(
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={ Home } />
                    <Route exact path="/:project" component={ ProjectDetails } />
                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById("Root"));